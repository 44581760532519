.footer-distributed {
  border: 0.001rem solid;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  padding: 8px 0px;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #F7F6F5;
}

.footer-distributed .footer-contact {
  display: flex;
  align-items: center;

}

.footer-distributed .footer-contact p {
  display: inline-block;
  color: black;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-contact p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
}