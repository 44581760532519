.sidebar {
  row-gap: 8px;
  flex-direction: column;
  display: flex;
  width:100%;
  max-width: 176px;
  border-right: 0.001rem solid #000080;
  background-color: #F7F6F5;
  padding-top: 16px;
}

.sidebar-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  padding: 0.5rem 0rem
}

.sidebar-icon {
  margin-right: 8px;
  color: '#00000 !important';
  padding-left: 16px;
}

.active {
  background-color: #DFF0FC;
  border-left: 4px solid #000080;
}

.ag-header-cell-label {
  font-weight: bold;
}

@media print {
  @page {
      margin: 5mm; /* Set the margins for each printed page */
  }

  .printable-content {
    margin: 10mm
  }

  /* For paragraphs, lists */
   /* ul {
      page-break-inside: avoid; 
      page-break-after: auto; 
    } 
  */

  .no-print {
      display: none !important; /* Hide elements marked as no-print during print */
  }
}
